import { AdminV2NewCatalog } from '@modules/admin-v2-catalog/models/admin-v2-catalog.model';
import { AdminV2NewCommunity } from '@modules/admin-v2-community/models/admin-v2-community.model';
import { AdminV2NewGroup } from '@modules/admin-v2-group/models/admin-v2-group.model';
import {
    AdminV2NewUser,
    AdminV2ResetCustomCredentials,
    AdminV2UserCredentials,
} from '@modules/admin-v2-user/models/admin-v2-user.model';
import { AdminV2NewWorkspace } from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { createAction, props } from '@ngrx/store';

const prefix = '[AdminV2 Shared]';

export const init = createAction(`${prefix} Init Store`);

export const openCreationDialog = createAction(
    `${prefix} Open Creation Dialog`,
    props<{
        isOpen: boolean;
    }>(),
);

// EDIT MANGEMENT
export const editButton = createAction(`${prefix} Edit Button`);
export const editCancelButton = createAction(`${prefix} Edit Cancel Button`);
export const editSaveButton = createAction(`${prefix} Edit Save Button`);

// CREATION
export const createGroup = createAction(
    `${prefix} Create Group`,
    props<{
        group: AdminV2NewGroup;
    }>(),
);
export const createUser = createAction(
    `${prefix} Create User`,
    props<{
        user: AdminV2NewUser;
        credentials: AdminV2UserCredentials;
        command?: AdminV2ResetCustomCredentials;
    }>(),
);
export const createWorkspace = createAction(
    `${prefix} Create Worksapce`,
    props<{
        workspace: AdminV2NewWorkspace;
    }>(),
);
export const createCommunity = createAction(
    `${prefix} Create community`,
    props<{
        community: AdminV2NewCommunity;
    }>(),
);
export const createCatalog = createAction(
    `${prefix} Create Catalog`,
    props<{
        catalog: AdminV2NewCatalog;
    }>(),
);

export const setFlushOnLeave = createAction(
    `${prefix} Set Flush on Leave`,
    props<{
        flushOnLeave: boolean;
    }>(),
);
